














































































































import { Component, Watch } from "vue-property-decorator";
import { Account } from "@/interfaces/Account";
import Loader from "@/components/Loader.vue";
import SearchBar from "@/components/dossier/SearchBar.vue";
import DjlMultiselect from "@/components/form-components/djlMultiselect.vue";
import { TranslateResult } from "vue-i18n";
import DocumentList from "@/components/dossier/DocumentList.vue";
import mFiles, { DossierDocument } from "@/api/mFiles";
import { AxiosResponse } from "axios";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

import accessControl from "@/api/access_control";
import { resolvePromiseSettledResult } from "@/utils/promises";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";

@Component({
  props: {
    selectedAccountIds: Array,
    searchFilter: String,
  },
  methods: { accountLabel },
  components: {
    djlMultiSelect,
    DocumentList,
    Loader,
    SearchBar,
    DjlMultiselect,
  },
})
@Translatable("dossier")
export default class EmployeeDossier extends TranslatableComponent {
  private isLoadingAccounts: boolean = true;
  private isLoadingDossierDocuments: boolean = false;
  private documents: DossierDocument[] = [];
  private accounts: Account[] = [];
  private filter: string = "";
  private selectedAccounts: Account[] = [];

  onSearchBarChange(value: string) {
    this.filter = value;
  }

  get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  get hasSelectedAccount(): boolean {
    return this.selectedAccounts.length > 0;
  }

  get hasActiveFilter(): boolean {
    return !!this.filter;
  }

  get noResultsMessage(): TranslateResult {
    return this.hasSelectedAccount
      ? this.hasDocuments
        ? this.hasActiveFilter
          ? this.translated_view_value("no_documents_in_selection")
          : ""
        : this.selectedAccounts.length > 1
        ? this.translated_view_value("selected_entities_have_no_documents")
        : this.translated_view_value("selected_entity_has_no_documents")
      : "";
  }

  mounted() {
    accessControl.accounts
      .accounts()
      .then((response: AxiosResponse<Account[]>) => {
        this.accounts = response.data;
        this.renderDossierDocuments();
        this.filter = this.$props.searchFilter;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_api_value("access_control.error.fetch_accounts")
        );
        this.accounts = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
      });
  }

  @Watch("$props.selectedAccountIds")
  async renderDossierDocuments() {
    this.determineSelectedAccounts();

    if (this.selectedAccounts.length === 0) {
      this.documents = [];
      return;
    }

    this.isLoadingDossierDocuments = true;
    await this.loadDocuments();
    this.isLoadingDossierDocuments = false;
  }

  determineSelectedAccounts() {
    let selectedAccounts = [];

    for (let account of this.accounts)
      if (this.$props.selectedAccountIds.includes(account.id))
        selectedAccounts.push(account);

    this.selectedAccounts = selectedAccounts;
  }

  async loadDocuments(): Promise<DossierDocument[]> {
    this.documents = [];
    const dossierDocumentsPromises: Promise<DossierDocument[]>[] = [];

    this.selectedAccounts.forEach((account: Account) => {
      dossierDocumentsPromises.push(this.getDossierDocuments(account));
    });

    return Promise.allSettled(dossierDocumentsPromises)
      .then(
        (results) => (this.documents = resolvePromiseSettledResult(results))
      )
      .catch(() => [] as DossierDocument[]);
  }

  getDossierDocuments(selectedAccount: Account): Promise<DossierDocument[]> {
    return mFiles.getDocumentsWithAccount(selectedAccount).catch(() => {
      this.selectedAccounts =
        this.selectedAccounts.filter(
          (selectedValue: any) => selectedValue !== selectedAccount
        ) || null;

      this.$snotify.error(
        this.translated_view_value("can_not_get_documents", {
          name: selectedAccount.name,
        })
      );

      return [];
    });
  }

  onChange(selectedAccounts: Account[]) {
    this.$router.push({
      name: "dossier",
      query: {
        selectedAccountIds: selectedAccounts.map((account) => account.id),
      },
    });
  }
}
